import i18n from 'i18next';
import { Toast } from '@swingvy/design-system';
import $ from 'js/jquery';
import DataProvider from 'js/network/DataProvider';
import API from 'js/network/IntroApi';

export const fetchIndustries = () =>
    new Promise((resolve, reject) => {
        new DataProvider(API.BUSINESS_INDUSTRIES)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const industries = result.businessIndustries.map((bi) => ({
                    value: bi.id,
                    title: bi.name,
                }));
                resolve(industries);
            })
            .setErrorCallback((error) => reject(error))
            .request();
    });

export const fetchCountries = () =>
    new Promise((resolve, reject) => {
        new DataProvider(API.COUNTRIES)
            .useCache(false)
            .addQuery('filter', 'office')
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const countries = result.countries
                    .filter((country) => country.code !== 'KR')
                    .map((country) => ({
                        value: country.code,
                        title: country.name,
                    }));
                resolve(countries);
            })
            .setErrorCallback((error) => reject(error))
            .request();
    });

export const fetchSubdivisions = (countryCode) =>
    new Promise((resolve, reject) => {
        new DataProvider(API.SUB_DIVISIONS, countryCode)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const countrySubdivisions = result.countrySubdivisions.map((subDivision) => ({
                    value: subDivision.id,
                    title: subDivision.name,
                }));
                resolve(countrySubdivisions);
            })
            .setErrorCallback((error) => reject(error))
            .request();
    });

export const fetchAllCountries = () =>
    new Promise((resolve, reject) => {
        new DataProvider(API.ALL_COUNTRIES)
            .setCallback((result) => {
                resolve(result.countries);
            })
            .setErrorCallback((error) => reject(error))
            .request();
    });

export const fetchNumberOfEmployeeOptions = () =>
    new Promise((resolve, reject) => {
        new DataProvider(API.NUMBER_OF_EMPLOYEES_OPTIONS)
            .useCache(false)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const options = result.numberOfEmployeesOptions.map((option) => ({
                    value: option.name,
                    title: option.name,
                }));
                resolve(options);
            })
            .setErrorCallback((error) => reject(error))
            .request();
    });

export const register = (param) =>
    new Promise((resolve, reject) => {
        new DataProvider(API.REGISTER)
            .setParam(JSON.stringify(param))
            .setCallback((result) => {
                resolve({
                    meta: result.meta,
                    session: result.session,
                    key: result.key,
                });
            })
            .setErrorCallback((error) => {
                if (error.code) {
                    reject(error);
                } else {
                    Toast.Builder('error')
                        .setHeader(i18n.t('common.oops'))
                        .setBody(i18n.t('error.msg_temp_network'))
                        .build()
                        .show();
                }
            })
            .request();
    });

export const authRegisterDone = (param) =>
    new Promise((resolve, reject) => {
        $.ajax({
            type: API.REGISTER_DONE_JWT.method,
            url: `${API.REGISTER_DONE_JWT.url}?key=${encodeURIComponent(
                param.key,
            )}&email=${encodeURIComponent(param.email)}`,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        })
            .done(() => resolve())
            .fail((error) => reject(error));
    });

export const refreshToken = () =>
    new Promise((resolve, reject) => {
        $.ajax({
            type: API.REFRESH.method,
            url: API.REFRESH.url,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        })
            .done(() => resolve())
            .fail((error) => reject(error));
    });
