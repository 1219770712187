import React, { useState } from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import {
    Form,
    Input,
    Select,
    Button,
    Banner,
    EnhancedSelect,
    NumberFormatInput,
    Checkbox,
} from '@swingvy/design-system';
import { sendEvent, HrisEvents } from '@swingvy/frontend-util/data-events';
import { ConstantsUtil } from '@swingvy/frontend-util';

import $ from 'js/jquery';
import RoutePath from 'js/app/RoutePath';
import ErrorModal from 'js/components/common/modal/ErrorModal';
import Logger from 'js/helper/Logger';
import { register, authRegisterDone, refreshToken } from './util';

import 'style-loader!./register-content.less';

const { CommonEventName } = HrisEvents;
const { COUNTRY_CODE } = ConstantsUtil;
const MAILTO_SUPPORT = 'mailto:support@swingvy.com';
const DELIMITER = '|';

const makeUniqCountryPhoneCode = (country, delimiter = DELIMITER) =>
    `${country.name}${delimiter}${country.phoneCode}`;

const RegisterContent = ({
    industries,
    countries,
    subdivisions,
    updateSubdivisions,
    allCountries,
    numberOfEmployeeOptions,
}) => {
    const [formRef, setFormRef] = useState(null);
    const [fullName, setFullName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordId, setPasswordId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [noOfEmployee, setNoOfEmployee] = useState('');
    const [phoneNumberObj, setPhoneNumberObj] = useState(null);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedSubdivision, setSelectedSubdivision] = useState(null);
    const [selectedPhoneCountry, setSelectedPhoneCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPhoneNumberFocus, setIsPhoneNumberFocus] = useState(false);
    const [emailVerifyModal, setEmailVerifyModal] = useState({
        show: false,
        email: null,
        key: null,
    });
    const [showDuplicateEmailBanner, setShowDuplicateEmailBanner] = useState(false);
    const [checkTermsAndPrivacy, setCheckTermsAndPrivacy] = useState(false);

    const phoneOptions = allCountries.map(({ name, phoneCode }) => ({
        value: makeUniqCountryPhoneCode({ name, phoneCode }),
        title: `${name} (${phoneCode})`,
        selectedTitle: phoneCode,
    }));

    const onSubmit = async () => {
        setIsLoading(true);
        const phoneCountryNumber = selectedPhoneCountry?.phoneCode || '';
        const phoneLocalNumber = phoneNumberObj?.formattedValue || '';
        const phoneNumber = `${phoneCountryNumber} ${phoneLocalNumber}`;
        try {
            const result = await register({
                fullName,
                companyName,
                email,
                password,
                noOfEmployee,
                phoneNumber,
                businessIndustryId: selectedIndustry,
                countryCode: selectedCountry,
                countrySubdivisionId: selectedSubdivision,
            });
            setEmailVerifyModal({
                show: true,
                key: result.key,
                email,
            });
            const { user, company } = result.session;
            sendEvent(CommonEventName.SIGN_UP, {
                user_id: user.id,
                email: user.email,
                name: user.name,
                company_id: company.id,
                company_name: company.name,
                phone_number: phoneNumber,
                business_industry: selectedIndustry,
                number_of_employees: noOfEmployee,
                country: selectedCountry,
                register_type: 'general',
            });
        } catch (error) {
            sendEvent(CommonEventName.SIGN_UP);
            if (error.code === 701) {
                setShowDuplicateEmailBanner(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="content-box company-register-box crb">
                <div className="content-inner-box">
                    <div className="title-area">
                        <div className="content-title">{i18n.t('register.title')}</div>
                    </div>
                    <div
                        className={`register-error-box ${
                            showDuplicateEmailBanner ? 'show-el' : ''
                        }`}
                    >
                        <Banner
                            type="error"
                            header={
                                <span>
                                    {`${i18n.t('register.error.duplicate_email_n_login.text1')} `}
                                    <a href={RoutePath.LOGIN}>
                                        {i18n.t(
                                            'register.error.duplicate_email_n_login.text2_login',
                                        )}
                                    </a>
                                    {` ${i18n.t('register.error.duplicate_email_n_login.text3')} `}
                                    <a href={MAILTO_SUPPORT}>
                                        {i18n.t(
                                            'register.error.duplicate_email_n_login.text4_support_team',
                                        )}
                                    </a>
                                    {` ${i18n.t('register.error.duplicate_email_n_login.text5')}`}
                                </span>
                            }
                            onClickClose={() => setShowDuplicateEmailBanner(false)}
                        />
                    </div>
                    <Form formRef={(el) => setFormRef(el)} submitHandler={onSubmit}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <Input
                                    labelTitle={i18n.t('form.fullname.label')}
                                    required
                                    value={fullName}
                                    onChange={(value) => setFullName(value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Input
                                    labelTitle={i18n.t('register.form.company_name.label')}
                                    required
                                    value={companyName}
                                    onChange={(value) => setCompanyName(value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <Input
                                    labelTitle={i18n.t('form.email.label')}
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(value) => setEmail(value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="crb__phone">
                                    <div className="crb__phone__select">
                                        <EnhancedSelect
                                            required
                                            labelTitle={
                                                <span>{i18n.t('form.phone_number.label')}</span>
                                            }
                                            placeholder=""
                                            options={phoneOptions}
                                            value={
                                                selectedPhoneCountry
                                                    ? makeUniqCountryPhoneCode(selectedPhoneCountry)
                                                    : ''
                                            }
                                            onChange={({ value }) => {
                                                const country = allCountries.find(
                                                    ({ phoneCode }) =>
                                                        phoneCode === value.split(DELIMITER)[1],
                                                );
                                                setSelectedPhoneCountry(country);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`crb__phone__input ${
                                            isPhoneNumberFocus ? 'crb__phone__input--focus' : ''
                                        }`}
                                    >
                                        <NumberFormatInput
                                            required
                                            format="###-###-####"
                                            value={phoneNumberObj ? phoneNumberObj.value : ''}
                                            onChange={(values) => setPhoneNumberObj(values)}
                                            onFocus={() => setIsPhoneNumberFocus(true)}
                                            onBlur={() => setIsPhoneNumberFocus(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <Input
                                    labelTitle={i18n.t('form.password.label')}
                                    type="password"
                                    onIdCreated={(id) => setPasswordId(id)}
                                    required
                                    minLength={8}
                                    value={password}
                                    onChange={(value) => setPassword(value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Input
                                    labelTitle={i18n.t('form.password.label_confirm')}
                                    type="password"
                                    equalTo={`#${passwordId}`}
                                    required
                                    minLength={8}
                                    value={confirmPassword}
                                    onChange={(value) => setConfirmPassword(value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <Select
                                    labelTitle={i18n.t('register.form.business_industry.label')}
                                    placeholder={i18n.t(
                                        'register.form.business_industry.placeholder',
                                    )}
                                    required
                                    options={industries}
                                    value={selectedIndustry}
                                    onChange={(option) => setSelectedIndustry(option.value)}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Select
                                    labelTitle={i18n.t('form.no_of_employees.label')}
                                    placeholder={i18n.t('form.no_of_employees.placeholder')}
                                    required
                                    options={numberOfEmployeeOptions}
                                    value={noOfEmployee}
                                    onChange={(option) => setNoOfEmployee(option.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <Select
                                    labelTitle={i18n.t('register.form.country.label')}
                                    placeholder={i18n.t('register.form.country.placeholder')}
                                    required
                                    options={countries}
                                    value={selectedCountry}
                                    onChange={(option) => {
                                        setSelectedCountry(option.value);
                                        setSelectedSubdivision(null);
                                        updateSubdivisions(option.value);
                                    }}
                                />
                            </div>
                            {(selectedCountry === COUNTRY_CODE.MALAYSIA ||
                                selectedCountry === COUNTRY_CODE.TAIWAN) && (
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={
                                            selectedCountry === COUNTRY_CODE.TAIWAN
                                                ? i18n.t('form.state.label_tw')
                                                : i18n.t('register.form.state.label')
                                        }
                                        placeholder={i18n.t('register.form.state.placeholder')}
                                        required
                                        options={subdivisions}
                                        value={selectedSubdivision}
                                        onChange={(option) => setSelectedSubdivision(option.value)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="company-sign-up-box">
                            <div className="agree-check">
                                <Checkbox
                                    checked={checkTermsAndPrivacy}
                                    onChange={(value) => setCheckTermsAndPrivacy(value)}
                                    required
                                    errorMessage={i18n.t(
                                        'register.form.agreement.validation.error_message',
                                    )}
                                    labelTitle={
                                        <Trans
                                            i18nKey="register.form.agreement.text"
                                            components={[
                                                <a
                                                    className="swv-btn swv-btn-links swv-theme-light"
                                                    href={RoutePath.SWINGVY_LANDING.TOS}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {i18n.t('register.form.agreement.terms')}
                                                </a>,
                                                <a
                                                    className="swv-btn swv-btn-links swv-theme-light"
                                                    href={RoutePath.SWINGVY_LANDING.PRIVACY}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {i18n.t(
                                                        'register.form.agreement.privacy_policy',
                                                    )}
                                                </a>,
                                            ]}
                                        />
                                    }
                                />
                            </div>
                            <Button
                                className="btn-company-sign-up"
                                type="primary"
                                size="large"
                                title={i18n.t('register.form.submit_button.title')}
                                isLoading={isLoading}
                                onClick={(env) => {
                                    env.preventDefault();
                                    $(formRef).submit();
                                }}
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <div className="login-guide-box">
                <div className="login-guide-text">
                    {i18n.t('register.txt_already_have_account')}
                    <a
                        className="swv-btn swv-btn-links swv-theme-light"
                        href={RoutePath.LOGIN}
                    >{` ${i18n.t('register.link_login')}`}</a>
                </div>
            </div>

            <ErrorModal
                className="gtm-verify-email-modal"
                show={emailVerifyModal.show}
                showCloseBtn={false}
                type="standard"
                title={i18n.t('register.verify_email_modal.header')}
                message={i18n.t('register.verify_email_modal.message')}
                cancelBtnTitle={i18n.t('register.verify_email_modal.button_title')}
                onClickCancelBtn={async () => {
                    setEmailVerifyModal({
                        ...emailVerifyModal,
                        show: false,
                    });
                    try {
                        await authRegisterDone({
                            key: emailVerifyModal.key,
                            email: emailVerifyModal.email,
                        });
                        await refreshToken();
                    } catch (error) {
                        Logger.log(error);
                    } finally {
                        document.location.href = '/main.html';
                    }
                }}
            />
        </>
    );
};

export default RegisterContent;
