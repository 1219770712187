import React from 'react';
import RoutePath from 'js/app/RoutePath';
import i18n from 'i18next';

import 'style-loader!./page-frame.less';

const PageFrame = (props) => {
    return (
        <div className="swv-frame-layout">
            <div className="swv-content-container">
                <div className="swv-container container-fluid">
                    <div className="header-container register-header-container">
                        <div className="container-fluid">
                            <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                <div className="logo-img _swingvy_logo_img" />
                            </a>
                        </div>
                    </div>

                    <div className="main-container">
                        <div className="row main-inner-container">
                            <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8">
                                {props.children}
                            </div>
                        </div>
                    </div>

                    <div className="footer-container">
                        <div className="container">
                            <div className="link-item">
                                <a
                                    href={RoutePath.SWINGVY_LANDING.PRIVACY}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {i18n.t('intro_footer.privacy.link')}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href={RoutePath.SWINGVY_LANDING.TOS}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {i18n.t('intro_footer.terms.link')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageFrame;
