import React, { useState, useEffect } from 'react';
import Logger from 'js/helper/Logger';
import {
    fetchIndustries,
    fetchCountries,
    fetchAllCountries,
    fetchNumberOfEmployeeOptions,
    fetchSubdivisions,
} from './util';
import PageFrame from './PageFrame';
import RegisterContent from './RegisterContent';

const RegistrationPage = () => {
    const [industries, setIndustries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [numberOfEmployeeOptions, setNumberOfEmployeeOptions] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);

    useEffect(() => {
        Promise.all([
            fetchIndustries(),
            fetchCountries(),
            fetchAllCountries(),
            fetchNumberOfEmployeeOptions(),
        ])
            .then(
                ([
                    industriesData,
                    countriesData,
                    allCountriesData,
                    numberOfEmployeeOptionsData,
                ]) => {
                    setIndustries(industriesData);
                    setCountries(countriesData);
                    setAllCountries(allCountriesData);
                    setNumberOfEmployeeOptions(numberOfEmployeeOptionsData);
                },
            )
            .catch((error) => {
                Logger.log(error);
            });
    }, []);

    const updateSubdivisions = (countryCode) => {
        if (!countryCode) return;
        fetchSubdivisions(countryCode)
            .then((fetchedSubdivisions) => {
                setSubdivisions(fetchedSubdivisions);
            })
            .catch((error) => {
                Logger.log(error);
            });
    };

    return (
        <PageFrame>
            <RegisterContent
                industries={industries}
                countries={countries}
                subdivisions={subdivisions}
                updateSubdivisions={updateSubdivisions}
                allCountries={allCountries}
                numberOfEmployeeOptions={numberOfEmployeeOptions}
            />
        </PageFrame>
    );
};

export default RegistrationPage;
