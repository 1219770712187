import 'react-hot-loader/patch';
import $ from 'js/jquery';
import _ from 'underscore';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import Logger from 'js/helper/Logger';
import API from 'js/network/IntroApi';
import { setI18n, Toast } from '@swingvy/design-system';
import { SessionUtil, LocationUtil } from '@swingvy/frontend-util';
import { isDevPhase, ERROR_CODE } from 'js/util/Constants';
import i18n from 'i18next';
import { I18N_PROJECT, initI18n, renderAppWithI18n } from 'js/helper/i18n';
import { initialize } from 'js/helper/DocumentTitleManager';
import { renderReactElement } from 'js/util/ViewUtil';
import ForgotPasswordView from 'js/intro_app/view/ForgotPasswordView';
import NewPasswordView from 'js/intro_app/view/NewPasswordView';
import RegistrationPage from 'js/intro_app/view/register';
import MdecCheckView from 'js/intro_app/view/MdecCheckView';
import OnBoardingApp from 'js/onboarding_app/OnBoardingApp';
import DownloadPayslip from 'js/intro_app/view/DownloadPayslip';
import LocalStorage from 'js/storage/LocalStorage';
import { isPayrollSupportCountry } from 'js/helper/BillingHelper';
import LoginPage, { refreshTokenAndLogin } from 'js/app/view/login/LoginPage';
import { rest } from 'js/network/apiConfig';
import RoutePath from 'js/app/RoutePath';

import 'style-loader!bootstrap/dist/css/bootstrap.css';
import 'style-loader!font-awesome/css/font-awesome.css';
import 'style-loader!toastr/build/toastr.css';
import 'style-loader!parsleyjs/src/parsley.css';
import 'style-loader!bootstrap-select/dist/css/bootstrap-select.css';
import 'style-loader!@swingvy/design-system/dist/swingvy-components.css';
import 'style-loader!css/swingvy_intro_app_v1.less';

console.log(`🌻[App version]🌻: ${process.env.APP_VERSION}`);

initI18n({
    defaultProject: I18N_PROJECT.INTRO,
    projects: [I18N_PROJECT.COMMON, I18N_PROJECT.INTRO, I18N_PROJECT.HRIS],
});
setI18n(i18n);

const createParamStr = () => {
    try {
        const params = location.search.substr(1).split('&');
        params.shift();
        const paramTexts = params.map((param) => `&${param}`);
        return paramTexts.length > 0 ? paramTexts.reduce((prev, curr) => prev + curr) : '';
    } catch (error) {
        console.log(error);
        return '';
    }
};

const redirectLocation = (session, redirectPath) => {
    const MAIN_PAGE = 'main.html';
    const SWITCH_ACCOUNT_PAGE = 'switch-account.html';
    if (SessionUtil.isAgentUser(session)) {
        document.location.href = SWITCH_ACCOUNT_PAGE;
    } else if (redirectPath) {
        const matchHttpPage = redirectPath.match(/^(http|https)/gi);
        const pathHasHttpPage = matchHttpPage && matchHttpPage.length > 0;
        if (pathHasHttpPage) {
            document.location.href = redirectPath;
        } else {
            document.location.href = `${MAIN_PAGE}#${redirectPath}${createParamStr()}`;
        }
    } else {
        document.location.href = MAIN_PAGE;
    }
};

Logger.init();

const checkSession = (errorCallback) => {
    axios
        .get(API.SESSION.url)
        .then(({ data }) => {
            const { session } = data.data;
            Logger.setContext(session);

            const hashPaths = LocationUtil.getHashPaths();
            if (hashPaths.length > 1 && hashPaths[1] === 'pro') {
                LocalStorage.setCurrentSession(session);
                if (isPayrollSupportCountry(session)) {
                    LocalStorage.set(LocalStorage.EnableFreeTrial, true);
                    LocalStorage.set(LocalStorage.CountryNotSupported, false);
                } else {
                    LocalStorage.set(LocalStorage.CountryNotSupported, true);
                }
                redirectLocation(session);
            } else {
                const redirectPath = LocationUtil.getParamValue('redirect');
                redirectLocation(session, redirectPath);
            }
        })
        .catch((error) => {
            const resp = error.response;
            if (resp && resp.status === 500) {
                if (resp.data && resp.data.code === ERROR_CODE.ACCESS_TOKEN_EXPIRED) {
                    axios
                        .post(API.REFRESH.url)
                        .then(() => checkSession(errorCallback))
                        .catch(errorCallback);
                    return;
                }
            }
            errorCallback(error);
        });
};

const render = function () {
    const hashPaths = LocationUtil.getHashPaths();
    const options = _.assign({}, LocationUtil.getHashParams(), {
        hashPaths,
        i18next: i18n,
        i18n,
    });

    ReactDOM.unmountComponentAtNode(document.getElementById('content'));
    switch (hashPaths[0]) {
        case 'login':
            checkSession(() => {
                renderReactElement(
                    <LoginPage options={options} />,
                    document.getElementById('content'),
                );
            });
            break;
        case 'sso':
            const code = LocationUtil.getParamValue('code', document.location.href);
            if (code) {
                rest.session
                    .loginWithGoogle({ code, ssoConnection: 'GOOGLE_WEB' })
                    .then(({ data: result }) => {
                        refreshTokenAndLogin(result);
                    })
                    .catch((error) => {
                        switch (error.code) {
                            case 601:
                                const { origin } = document.location;
                                document.location.href = `${RoutePath.LOGOUT}?redirect=${origin}`;
                                break;
                            case 608: // onboarding process
                                window.location.href = error.data?.location;
                                window.location.reload();
                                break;
                            case 606: // unverified account
                            case 611: // before the invitation date
                                document.location.replace(
                                    `#/login?errorCode=${error.code}&errorMessage=${error.message}&verifyEmail=${error.data?.email}`,
                                );
                                break;
                            default:
                                Toast.Builder('error')
                                    .setHeader(i18n.t('login.msg_ooops'))
                                    .setBody(i18n.t('login.msg_temp_network_error'))
                                    .build()
                                    .show();
                                document.location.replace('#/login');
                        }
                    });
            } else {
                const errorCode = LocationUtil.getParamValue('error', document.location.href);
                Toast.Builder('error')
                    .setHeader(i18n.t(`login.sign_in_with_google.error.${errorCode}`))
                    .build()
                    .show();
                document.location.replace('#/login');
            }
            break;
        case 'etc':
            if (hashPaths[1] === 'forgotPassword') {
                renderReactElement(<ForgotPasswordView />, document.getElementById('content'));
            } else if (hashPaths[1] === 'newPassword' && options.q) {
                renderReactElement(
                    <NewPasswordView q={options.q} />,
                    document.getElementById('content'),
                );
            }
            break;
        case 'register':
            renderReactElement(
                <RegistrationPage hashPaths={hashPaths} />,
                document.getElementById('content'),
            );
            break;
        case 'check':
            import('js/intro_app/view/check').then((mod) => {
                const view = mod.default;
                $('#content').html(new view().render(options).el);
            });

            break;
        case 'mobile':
            import('js/intro_app/view/mobile').then((mod) => {
                const view = mod.default;
                $('#content').html(new view().render(options).el);
            });

            break;
        case 'on_boarding':
            renderReactElement(
                <OnBoardingApp q={options.q} status={options.status} />,
                document.getElementById('content'),
            );
            break;

        case 'mdec':
            renderReactElement(<MdecCheckView />, document.getElementById('content'));
            break;

        case 'download_payslip':
            renderReactElement(<DownloadPayslip />, document.getElementById('content'));
            break;

        default:
            document.location.replace('#/login');
    }
};

window.addEventListener('hashchange', render);

renderAppWithI18n(render, {
    isDev: isDevPhase(process.env.PHASE),
}).then(() => initialize(i18n));
